<template>
    <div class="cookies-modal">
        <div class="cookies-title">
            Pliki cookies
        </div>
        <div class="cookies-content fw-bold">
            <div class="cookies-content-text mt-3">
                Strona husqvarnasklep.pl wykorzystuje niezbędne pliki cookies aby zapewnić jej prawidłowe działanie oraz opcjonalne, analityczne pliki cookies.
                W każdym momencie możesz zmienić preferencje dotyczącze przechowywania plików cookies.
            </div>
            <div v-if="isShowSettings" class="cookies-settings-container">
                <template v-for="cookieCategory in cookiesCategoryList">
                    <div
                      class="cookie-settings-category-title cursor-pointer d-flex align-items-center mt-5"
                      @click="toggleVisibleCookie(cookieCategory.code)"
                    >
                        {{ cookieCategory.title }} ({{ cookieCategory.cookies.length }})
                        <div class="mx-3">
                            <YesNoSwitch
                                :disabled="cookieCategory.code === 'required'"
                                :checked="isCookieAccepted(cookieCategory.code)"
                                @onChange="toggleAcceptedCookie(cookieCategory.code)"
                            />
                        </div>
                    </div>
                    <p class="cookie-settings-category-description">
                        {{ cookieCategory.description }}
                    </p>
                    <div
                        class="cookie-settings-cookies-list mb-3" 
                        :class="{'d-none': !visibleCookies.includes(cookieCategory.code), 'font-size-12': !isMobile}"
                    >
                        <div class="row col-12 border-bottom">
                            <div class="col-lg-3 col-5">
                                Nazwa
                            </div>
                            <div class="col-lg-3 col-4">
                                Domena
                            </div>
                            <div v-if="!isMobile" class="col-4">
                                Opis
                            </div>
                            <div class="col-lg-2 col-3">
                                Typ
                            </div>
                        </div>
                        <template v-for="cookieData in cookieCategory.cookies">
                            <div class="row col-12 my-2 border-bottom">
                                <div class="col-lg-3 col-5"> 
                                    {{ cookieData.name }}
                                </div>
                                <div class="col-lg-3 col-4"> 
                                    {{ cookieData.domain }}
                                </div>
                                <div v-if="!isMobile" class="col-4"> 
                                    {{ cookieData.description }}
                                </div>
                                <div class="col-lg-2 col-3">
                                    {{ cookieData.type ?? 'Cookie' }}
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <div class="cookies-content-buttons mt-5">
                <div class="cookies-content-buttons-left d-flex">
                    <button class="btn btn-primary" :class="isMobile ? 'mr-1' : 'mr-2'" @click="confirmAllCookies">
                        Akceptuję wszystkie
                    </button>
                    <button class="btn btn-primary" @click="confirmOnlyRequired">
                        Akceptuję tylko wymagane
                    </button>
                </div>
                <div class="cookies-content-buttons-right">
                    <button v-if="!isShowSettings" @click="showSettings" class="btn btn-secondary text-white">
                        Ustawienia
                    </button>
                    <button v-else class="btn btn-secondary text-white" @click="confirmCookies(this.acceptedCookies)">
                        Zapisz
                    </button>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import YesNoSwitch from '@/components/YesNoSwitch.vue'

export default {
    emits: ['accept'],
    name: 'CookiesModal',
    components: {YesNoSwitch},
    data() {
        return {
            isShowSettings: false,
            visibleCookies: [],
            cookiesCategoryList: [
                {
                    code: 'required',
                    title: 'Niezbędne pliki cookies',
                    description: 'Pliki cookies niezbędne do prawidłowego działania strony internetowej.',
                    cookies: [
                        {
                            name: '__hsklep_session_token',
                            domain: 'husqvarnasklep.pl',
                            description: 'Umożliwia korzystanie z niezbędnych funkcji strony'
                        },
                        {
                            name: 'i18n_redirected',
                            domain: 'husqvarnasklep.pl',
                            description: 'Przechowuje informację o języku, w jakim wyświetlać strony statyczne'
                        },
                        {
                            name: 'currency',
                            domain: 'api.husqvarnasklep.pl',
                            description: 'Przechowuje kod używanej waluty'
                        },
                        {
                            name: 'language',
                            domain: 'api.husqvarnasklep.pl',
                            description: 'Przechowuje kod ISO używanego na stronie języka'
                        },
                        {
                            name: '__hsklep_cookies_modal_accepted',
                            domain: 'husqvarnasklep.pl',
                            type: 'localStorage',
                            description: 'Informacja o zaakceptowanych plikach cookies'
                        },
                        {
                            name: 'parcelId',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Identyfikator wybranego paczkomatu'
                        },
                        {
                            name: 'parcelAddress',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Adres wybranego paczkomatu'
                        },
                        {
                            name: 'order_id',
                            domain: 'husqvarnasklep.pl',
                            description: 'Tymczasowy identyfikator zamówienia'
                        },
                    ]
                },
                {
                    code: 'analityc',
                    title: 'Analityczne pliki cookies',
                    description: 'Pozwalają mierzyć ilość wizyt i zbierać informacje na temat ruchu na stronie, dzięki czemu możemy poprawić działanie naszej strony.',
                    cookies: [
                        {
                            name: '_ga',
                            domain: 'husqvarnasklep.pl',
                            description: 'Plik cookies używany przez Google Analitycs'
                        },
                        {
                            name: '_ga_WZ0NSNKN7P',
                            domain: 'husqvarnasklep.pl',
                            description: 'Plik cookies używany przez Google Analitycs'
                        },
                    ]
                },
                {
                    code: 'storage',
                    title: 'Funkcjonalne',
                    description: 'Pozwalają przechowywać dane w lokalnym magazynie HTML',
                    cookies: [
                        {
                            name: '__hsklep_footerPagesData',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Dane na temat ustawień stopki strony'
                        },
                        {
                            name: '__hsklep_shipmentHours',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Dane na temat godzin dostaw'
                        },
                        {
                            name: '__hsklep_menuData',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Dane na temat ustawień menu strony'
                        },
                        {
                            name: '__hsklep_motorcyclesData',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Lista aktualnych motocykli'
                        },
                        {
                            name: '__hsklep_homePageGalleryData',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Dane galerii na stronie głównej'
                        },
                        {
                            name: '__manufacturers',
                            domain: 'husqvarnasklep.pl',
                            type: 'sessionStorage',
                            description: 'Lista dostawców'
                        },
                    ]
                },
            ],
            acceptedCookies: [
                'required',
                'analityc',
                'storage'
            ]
        }
    },
    methods: {
        isCookieAccepted(cookieCategory) {
            return this.acceptedCookies.includes(cookieCategory)
        },
        showSettings() {
            this.isShowSettings = true
        },
        confirmAllCookies() {
            this.confirmCookies([
                'required',
                'analityc',
                'storage'
            ])
        },
        confirmOnlyRequired() {
            this.confirmCookies(['required'])
        },
        confirmCookies(acceptedCookies) {
            localStorage.setItem('__hsklep_cookies_modal_accepted', JSON.stringify(acceptedCookies))

            this.$emit('accept')
        },
        toggleVisibleCookie(cookieCategoryCode) {
            if (!this.visibleCookies.includes(cookieCategoryCode)) {
                this.visibleCookies.push(cookieCategoryCode)
            } else {
                this.visibleCookies.splice(this.visibleCookies.indexOf(cookieCategoryCode), 1)
            }
        },
        toggleAcceptedCookie(cookieCategoryCode) {
            if (!this.acceptedCookies.includes(cookieCategoryCode)) {
                this.acceptedCookies.push(cookieCategoryCode)
            } else {
                if (cookieCategoryCode !== 'required') {
                    this.acceptedCookies.splice(this.acceptedCookies.indexOf(cookieCategoryCode), 1)
                }
            }
        }
    }
}
</script>
<style>
</style>