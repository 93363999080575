<template>
  <div class="main-header sticky-navbar jsStickyNavbar">
    <div class="d-block d-md-none">
      <div class="row align-items-center mx-0">
        <div class="col-4 px-0">
          <a href="/koszyk" class="nav-link text-blue">
            <font-awesome-icon icon="shopping-cart" class="mr-2" aria-label="Koszyk" />Koszyk
          </a>
        </div>
        <div class="col-8 px-0">
          <a
            id="list-products-box"
            class="nav-link dropdown-toggle nav-link btn btn-warning font-weight-bold border-radius-0 px-lg-4 px-1"
            :class="{'active': cartButtonDropdownClicked, 'disabled': $store.state.cart.loadingCart}"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            @mouseover="cartButtonDropdownMouseEnter"
            @mouseleave="cartButtonDropdownMouseLeave"
            @click="clickCartButtonDropdown"
            @focusout="cartButtonDropdownFocusOut"
          >
            <div v-if="!$store.state.cart.loadingCart && $store.state.cart?.cart?.total">
              <span>{{ dropdownText }}</span>
              <!--<br><span v-if="$store.state.cart.cart.total_raw > 399.5"  class="font-size-12 color-available">
                Darmowa wysyłka
              </span>
              <span v-else class="font-size-12">
                Do darmowej wysyłki brakuje {{ formatPrice(400 - $store.state.cart.cart.total_raw) }} zł
              </span>-->
            </div>
            <span v-else>0 produktów</span>
          </a>
          <div v-if="!$store.state.cart.loadingCart && $store.state.cart?.cart"
              class="dropdown-menu p-3 cart-dropdown"
               aria-labelledby="navbarDropdown"
               :class="{ show: isCartDropdownVisible }"
               @mouseover="cartDropdownMouseEnter"
               @mouseleave="cartDropdownMouseLeave"
               @focusout="cartDropdownMouseLeave"
          >
            <div
              @click="hideCartDropdown"
              @focus="hideCartDropdown"
              class="close-box cursor-pointer float-right"
              style="position:absolute;width:9px;height:9px;margin-top:-7px;right: 20px;"
            ></div>
            <div class="table-responsive">
              <table
                v-if="!$store.state.cart.loadingCart && $store.state.cart?.cart?.products"
                class="w-100 table text-blue"
              >
                <tbody>
                  <tr
                    v-for="row in $store.state.cart.cart.products"
                    :key="row.key"
                  >
                    <td class="text-center">
                      <img :src="row.thumb" alt="Thumbnail" class="img-fluid" />
                    </td>
                    <td>
                      <div
                        class="product-name-box text-blue d-block font-weight-bold"
                      >
                        {{ row.name }}
                      </div>
                      {{ row.quantity }} x
                      <strong>{{ row.price_promoted ? row.price_promoted : row.price }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-for="(row, index) in $store.state.cart.cart.totals"
              :key="index"
              class="text-right text-blue"
            >
              {{ row.title.replace('u00f3', 'ó').replace('u0142', 'ł') }}:
              <strong>{{ row.text }}</strong>
            </div>

            <a
              href="/koszyk"
              class="nav-link btn btn-primary font-weight-bold border-radius-0 p-2 mt-3"
              >zobacz koszyk</a
            >
          </div>
        </div>
      </div>
    </div>

    <nav
      class="navbar align-items-normal navbar-expand-md pt-md-3 pt-0 navbar-light content-box menu-main"
    >
      <a href="/" class="navbar-brand d-flex align-items-center">
        <img
          src="/img/HusqvarnaSKLEP-Logo.svg"
          alt="Logo Husqvarna 2"
          class="img-fluid logo-husqvarna"
          width="300"
          height="47"
        />
      </a>

      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#collapseMainMenu"
        aria-controls="collapseMainMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        id="collapseMainMenu"
        class="collapse navbar-collapse flex-wrap mt-xl-0 mt-md-4 justify-content-xl-end justify-content-center"
      >
        <div
          class="d-md-flex justify-content-xl-end justify-content-center align-items-center"
        >
          <TopSearch v-if="!isMobile" :is-additional="false"/>
          <ul class="navbar-nav align-items-center">
            <template v-if="!$store.state.user.loading">
              <li v-if="!$store.state.user.logged" class="nav-item px-2">
                <a href="/login" class="nav-link text-blue">
                  <font-awesome-icon icon="user" class="mr-2" aria-label="Logowanie / rejestracja" />Logowanie /
                  Rejestracja
                </a>
              </li>
              <li v-else class="nav-item px-2">
                <a href="/moje-konto" class="nav-link text-blue">
                  <font-awesome-icon icon="user" class="mr-2" aria-label="Moje konto" />Moje konto
                </a>
              </li>
            </template>
            <li class="nav-item px-2 d-md-block d-none">
              <a href="/koszyk" class="nav-link text-blue">
                <font-awesome-icon icon="shopping-cart" class="mr-2" aria-label="Koszyk" />Koszyk
              </a>
            </li>
            <li class="cart-dropdown-parent nav-item px-md-2 dropdown d-md-block d-none">
              <a
                id="list-products-box"
                class="nav-link dropdown-toggle nav-link btn btn-warning font-weight-bold border-radius-0 px-lg-4 px-1"
                :class="{'active': cartButtonDropdownClicked, 'disabled': $store.state.cart.loadingCart}"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                @mouseover="cartButtonDropdownMouseEnter"
                @mouseleave="cartButtonDropdownMouseLeave"
                @focusout="cartButtonDropdownFocusOut"
                @click="clickCartButtonDropdown"
              >
                <div v-if="!$store.state.cart.loadingCart && $store.state.cart?.cart?.total">
                  <span>{{ dropdownText }}</span> <!--<br>
                  <span v-if="$store.state.cart.cart.total_raw > 399.5" class="font-size-12 color-available">
                    Darmowa wysyłka
                  </span>
                  <span v-else class="font-size-12">
                    Do darmowej wysyłki brakuje {{ formatPrice(400 - $store.state.cart.cart.total_raw) }} zł
                  </span>-->
                </div>
                <span v-else>0 produktów</span>
              </a>
              <div class="dropdown-menu p-3 cart-dropdown"
                   aria-labelledby="navbarDropdown"
                   :class="{ show: isCartDropdownVisible }"
                   @mouseover="cartDropdownMouseEnter"
                   @mouseleave="cartDropdownMouseLeave"
                   @focusout="cartDropdownMouseLeave"
              >
                <div
                  @click="hideCartDropdown"
                  @focus="hideCartDropdown"
                  class="close-box cursor-pointer float-right"
                  style="position:absolute;width:9px;height:9px;margin-top:-7px;right: 20px;"
                ></div>
                <div class="table-responsive" style="padding-top: -7px;">
                  <table
                    v-if="!$store.state.cart.loadingCart && $store.state.cart?.cart?.products"
                    class="w-100 table text-blue"
                  >
                    <tbody>
                      <tr
                        v-for="row in $store.state.cart.cart.products"
                        :key="row.key"
                      >
                        <td class="text-center">
                          <img :src="row.thumb" alt="Thumbnail" class="img-fluid" />
                        </td>
                        <td>
                          <div
                            class="product-name-box text-blue d-block font-weight-bold"
                          >
                            {{ row.name }}
                          </div>
                          {{ row.quantity }} x
                          <strong>{{ row.price_promoted ? row.price_promoted : row.price }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <template v-if="!$store.state.cart.loadingCart && $store.state.cart?.cart">
                  <div
                    v-for="(row, index) in $store.state.cart.cart.totals"
                    :key="index"
                    class="text-right text-blue"
                  >
                    {{ row.title.replace('u00f3', 'ó').replace('u0142', 'ł') }}:
                    <strong>{{ row.text }}</strong>
                  </div>
                </template>

                <a
                  href="/koszyk"
                  class="nav-link btn btn-primary font-weight-bold border-radius-0 p-2 mt-3"
                  >zobacz koszyk</a
                >
              </div>
            </li>
          </ul>
        </div>

        <ul
          class="navbar-nav mt-2 main-menu-box"
        >
          <li class="nav-item">
            <div class="js-closest-shipment-container font-weight-bold px-3" style="display: block; padding: 0.5rem 0; font-size:13px;">
              <span>Najbliższa wysyłka: </span>
              <WaitingLoader v-if="loadingTargetShipmentHour || !targetHour" additional-class="sk-chase-10" />
              <span v-else class="js-closest-shipment font-weight-600 font-size-14" style="background: #FFED00; color: rgba(0,37,81,0.85);"> {{ remainingTime }}</span>
            </div>
          </li>
          <li class="nav-item dropdown">
            <span
              id="navbarDropdownSearchParts"
              class="nav-link font-weight-bold text-blue font-size-15 px-2"
              role="button"
              >WYSZUKAJ CZĘŚCI</span
            >
            <div
              class="dropdown-menu"
              aria-labelledby="navbarDropdownSearchParts"
            >
              <div class="row align-items-center">
                <div class="col-md-4">
                  <div class="pl-4 mt-2">
                    <img
                      src="/img/bike_search.webp"
                      alt="Bike search"
                      class="img-fluid"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-md-4">
                      <div class="search-box">
                        <a
                          href="/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/rok-modelowy"
                          class="btn btn-primary text-uppercase w-100 py-4 mb-3"
                          >Szukaj po roczniku</a
                        >
                        <a
                          href="/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/model"
                          class="btn btn-primary text-uppercase w-100 py-4 mb-3"
                          >Szukaj po modelu</a
                        >
                        <a
                          href="/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/vin"
                          class="btn btn-primary text-uppercase w-100 py-4 mb-3"
                        >Szukaj po numerze VIN</a
                        >
                        <a
                          href="/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/number"
                          class="btn btn-primary text-uppercase w-100 py-4"
                          >Szukaj po numerze części</a
                        >
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-8">
                      <div class="text-box pr-md-4 mt-md-0 mt-4">
                        <p>
                          W tym miejscu serwis Husqvarnasklep.pl daje możliwość
                          szybkiego znalezienia, wyceny i zamówienia potrzebnej
                          Ci części.
                        </p>

                        <p>
                          Możesz szukać wybierając wg Rocznika, Modelu, numeru VIN lub
                          podając Numer (indeks) Części, jeśli takowy posiadasz.
                        </p>

                        <p>
                          Następnie, znalezioną na interaktywnych schematach
                          część dodaj bezpośrednio do swojego koszyka, życzymy
                          udanych zakupów!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown text-uppercase">
            <a
              class="nav-link font-weight-bold text-blue font-size-15 px-2"
              href="/motocykle-nowe-husqvarna-salon-poznan"
            >Motocykle</a>
          </li>

          <li
            class="nav-item dropdown"
          >
            <a
              class="nav-link font-weight-bold text-blue font-size-15 px-2 text-uppercase"
              href="/akcesoria"
            >AKCESORIA</a>
          </li>

          <li
            class="nav-item dropdown"
          >
            <a
              class="nav-link font-weight-bold text-blue font-size-15 px-2 text-uppercase"
              href="/akcesoria/?categoryIdentity=90&page=1&categoryType=rest-products"
            >ODZIEŻ I OCHRONA</a>
          </li>

          <li class="nav-item dropdown show">
            <a
              class="nav-link font-weight-bold text-blue font-size-15 px-2"
              href="/kontakt"
              >KONTAKT</a
            >
          </li>

          <li class="nav-item dropdown show">
            <a
              class="nav-link font-weight-bold text-blue font-size-15 px-2"
              href="http://pitbike.poznan.pl"
              target="_blank"
            >
              NA POCZĄTEK
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div v-if="isMobile">
      <TopSearch :is-additional="true"/>
    </div>
  </div>
</template>

<script>
import TopSearch from '@/components/TopSearch'
import WaitingLoader from '@/components/WaitingLoader'
import axios from '@/plugins/axios'
import { getSessionStorage, setSessionStorage } from '@/plugins/storage'

export default {
  components: {
    TopSearch,
    WaitingLoader,
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    function toggleShow($el) {
      if ($el.hasClass('show')) {
        $el.removeClass('show')
      } else {
        $el.addClass('show')
      }
    }

    function fixNavbarScroll() {
      if ($('#collapseMainMenu').is(':visible')) {
        $('body').addClass("fixed-position");
      } else {
        $('body').removeClass("fixed-position");
      }
    }

    setTimeout(() => {
      // const $ = require('jquery')

      $('#collapseMainMenu .nav-link').on('click', function () {
        const isAlreadyShow = $(this).hasClass('show')

        setTimeout(() => {
          if ($(this).hasClass('show') && isAlreadyShow) {
            const navLink = $(this),
              parent = navLink.parent(),
              dropdownMenu = $(this).siblings('.dropdown-menu')

            toggleShow(navLink)
            toggleShow(dropdownMenu)
            toggleShow(parent)
          }
        }, 50)
      })

      $('.navbar-toggler').on('click', function () {
        setTimeout(() => {
          fixNavbarScroll()
        }, 50)
      })
    }, 400)

    setInterval(() => {
      fixNavbarScroll()
    }, 1000)

    try {
      const storedShipmentHours = getSessionStorage('__hsklep_shipmentHours')

      let shipmentHours

      if (storedShipmentHours) {
        shipmentHours = JSON.parse(storedShipmentHours)
      } else {
        const response = await axios.put('/api/rest/oc/shipment_hour', {}, {
          headers: {
            apiauth: true
          }
        })

        shipmentHours = response.data.data

        setSessionStorage('__hsklep_shipmentHours', JSON.stringify(shipmentHours))
      }

      if (shipmentHours.hour) {
        this.targetHour = +shipmentHours.hour
      }

      if (shipmentHours.minute) {
        this.targetMinute = +shipmentHours.minute
      }
    } catch (e) {
      console.warn(e)
    }

    this.updateRemainingTime()
    setInterval(this.updateRemainingTime, 1000)

    this.loadingTargetShipmentHour = false
  },
  data() {
    return {
      cartButtonDropdownHover: false,
      cartDropdownHover: false,
      cartButtonDropdownClicked: false,
      targetHour: null,
      targetMinute: 0,
      loadingTargetShipmentHour: true,
      remainingTime: null
    }
  },
  computed: {
    isCartDropdownVisible() {
      return this.cartButtonDropdownHover || this.cartDropdownHover || this.cartButtonDropdownClicked
    },
    dropdownText() {
      const cartLength = this.$store.state.cart.cart.products.length
      let dropDownText = ''

      if (cartLength === 1) {
        dropDownText = this.$store.state.cart.cart.total.replace('element(y)(ów)', 'element')
      } else if (cartLength > 1 && cartLength < 5) {
        dropDownText = this.$store.state.cart.cart.total.replace('element(y)(ów)', 'elementy')
      } else {
        dropDownText = this.$store.state.cart.cart.total.replace('element(y)(ów)', 'elementów')
      }

      return dropDownText
    }
  },
  watch: {
    $route() {
      /* global $ */
      $('#collapseMainMenu').collapse('hide')
      this.cartButtonDropdownHover = false
      this.cartButtonDropdownClicked = false
    }
  },
  methods: {
    updateRemainingTime() {
      const now = new Date();
      const currentHour = now.getHours();

      if (now.getDay() > 5 || (now.getDay() === 5 && currentHour >= this.targetHour)) {
        this.remainingTime = 'w poniedziałek'

        return
      }

      if (currentHour >= this.targetHour) {
        now.setDate(now.getDate() + 1); // add one day
      }

      const targetTime = new Date(now);

      targetTime.setHours(this.targetHour, this.targetMinute, 0, 0);

      const timeDiff = targetTime - now;

      if (timeDiff < 0) { // if target time is already passed for the day
        targetTime.setDate(targetTime.getDate() + 1); // set target time to next day
        const newTimeDiff = targetTime - now;
        const newHours = Math.floor(newTimeDiff / (1000 * 60 * 60));
        const newMinutes = Math.floor((newTimeDiff / (1000 * 60)) % 60);
        this.remainingTime = `${newHours} godz. ${newMinutes} min.`;
      } else {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
        this.remainingTime = `${hours} godz. ${minutes} min.`;
      }
    },
    hideCartDropdown() {
      this.cartButtonDropdownHover = false
      this.cartDropdownHover = false
      this.cartButtonDropdownClicked = false
    },
    handleScroll (event) {
      if (this.isMobile && window.scrollY % 5 === 0) {
        this.cartButtonDropdownHover = false
        this.cartDropdownHover = false
        this.cartButtonDropdownClicked = false
      }
    },
    cartButtonDropdownMouseLeave() {
      this.cartButtonDropdownHover = false
    },
    cartButtonDropdownFocusOut() {
      this.cartButtonDropdownHover = false
      this.cartButtonDropdownClicked = false
    },
    cartDropdownMouseLeave() {
      this.cartDropdownHover = false
    },
    cartButtonDropdownMouseEnter() {
      this.cartButtonDropdownHover = true
      $('.toasted').hide()
    },
    cartDropdownMouseEnter() {
      this.cartDropdownHover = true
      $('.toasted').hide()
    },
    clickCartButtonDropdown() {
      if (this.cartButtonDropdownClicked) {
        this.cartButtonDropdownClicked = false
        this.cartButtonDropdownHover = false
      } else {
        this.cartButtonDropdownClicked = true
      }
    },
  }
}
</script>
