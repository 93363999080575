<template>
  <div class="search-index-box form-inline"
  :class="{
    'my-2 my-lg-0 ml-xl-auto': !isAdditional,
    'additional-search-box': isAdditional,
    'd-none': isAdditional && !isMobile
  }">
    <label for="search-products" class="d-none">
      Wyszukiwarka produktów
    </label>
    <input
      id="search-products"
      v-model="query"
      @keyup.enter="searchProduct()"
      class="form-control mr-sm-2"
      :class="{'additional-search-input': isAdditional}"
      type="text"
      aria-label="Wyszukiwarka produktów"
    />
    <button class="btn btn-default" aria-label="search">
      <div v-if="searching">
        <WaitingLoader
          additional-class="sk-chase-15"
        />
      </div>
      <font-awesome-icon
        v-else
        @click="searchProduct"
        icon="search"
        class="text-blue"
      />
    </button>
  </div>
</template>
<script>
import Response from '@/models/response'
import Search from '@/plugins/search'
import WaitingLoader from '@/components/WaitingLoader'
import {toast} from "vue3-toastify";

export default {
  name: 'TopSearch',
  components: {
    WaitingLoader,
  },
  props: [
    'isAdditional'
  ],
  data() {
    return {
      query: '',
      searching: false
    }
  },
  computed: {
    isResultsPage() {
      return this.$route.path === '/results'
    }
  },
  methods: {
    imageError(event) {
      event.target.src = process.env.VUE_APP_API_RESOURCES + 'image/cache/catalog/part-228x228.png'
    },
    clearSearch() {
      this.query = ''
    },
    formatQuery() {
      this.query = this.query.trim()
    },
    searchProduct() {
      if (!this.query) {
        toast.info('Podaj numer części, lub nazwę produktu.')

        return null
      }

      this.searching = true
      this.formatQuery()

      localStorage.removeItem('searchProducts')
      localStorage.removeItem('searchQuery')

      Search(this)
        .search(this.query)
        .then(response => {
          const resp = new Response(response)

          if (resp.error && resp.error.length) {
            for (const i in resp.error) {
              toast.error(resp.error[i])
            }

            return
          }

          if (resp.data.length === 1 && resp.data[0].href && resp.data[0].number?.toLowerCase() === this.query.toLowerCase()) {
            this.searching = false
            window.location.href = resp.data[0].href

            return
          }

          if (resp.api === false && resp.data && resp.data.length > 0) {
            localStorage.setItem('searchProducts', JSON.stringify(resp.data))
            localStorage.setItem('searchQuery', this.query)

            this.searching = false

            window.location.href = '/results'
          } else if (resp.api === true) {
            if (resp.data.searching === true) {
              toast.info('Aktualnie trwa już wyszukiwanie tego produktu, spróbuj ponownie za kilka sekund.')
            } else if (resp.data.not_found === true) {
              toast.error('Brak wyników wyszukiwania')
            } else {
              toast.info(
                'Brak wyników wyszukiwania. Rozpoczęto wyszukiwanie produktu, spróbuj ponownie za kilka sekund.'
              )
            }
          } else {
            toast.error('Brak wyników wyszukiwania')
          }

          this.searching = false
        })
    }
  }
}
</script>
