<template>
    <label class="yes-no-switch" :class="{'disabled': disabled}">
        <input :class="{'disabled': disabled}" type="checkbox" :checked="checked" @change="!disabled ? $emit('onChange'): null">
        <span class="yes-no-slider round"></span>
    </label>
</template>
<script>
export default {
    name: 'YesNoSwitch',
    props: [
        'disabled', 
        'checked'
    ],
}
</script>