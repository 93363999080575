import { ref } from 'vue'
import axios from '@/plugins/axios'
import { createStore } from 'vuex'
import cart from '@/store/cart'
import user from '@/store/user'
import alertStore from '@/store/alert'
import { TOKEN_SESSION_STORAGE } from '@/plugins/constants'
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import { getSessionStorage, setSessionStorage } from '@/plugins/storage'

const state = {
    homePage: ref({}),
    footer: ref({}),
    title: ref(''),
    settings: ref({}),
    mainMenu: {
        categories: ref([]),
        manufacturers: ref([]),
        motorcycles: ref([])
    },
    footerMenu: ref({}),
    access_token: ref({}),
    breadcrumbs: ref({}),
    termModal: ref({}),
    cookiesModal: ref({value: false})
}

const mutations = {
    setApiToken(state, value) {
        state.access_token.value = value
    },
    setHomePage(state, value) {
        state.homePage.value = value
    },
    setTitle(state, value) {
        state.title.value = value
    },
    setSettings(state, value) {
        state.settings.value = value
    },
    setMainMenu(state, value) {
        state.mainMenu = value
    },
    setFooterMenu(state, value) {
        state.footerMenu.value = value
    },
    setFooter(state, value) {
        state.footer.value = value
    },
    setBreadcrumbs(state, value) {
        state.breadcrumbs.value = value
    },
    setTermModal(state, value) {
        state.termModal.value = value
    },
    setCookiesModalShow(state, value) {
        state.cookiesModal.value = value
    }
}

const actions = {
    async token({ commit }, forceRefresh = false) {
        const post = {}

        const accessToken = cookies.get(TOKEN_SESSION_STORAGE)

        if (!accessToken || forceRefresh) {
            // post.old_token = accessToken

            const apiRest = (
                await axios.post(
                    '/api/rest/oauth2/token/client_credentials',
                    post,
                    {
                        headers: {
                            Authorization: 'Basic cmVkaWNvbjpyZWRpY29uX3NlY3JldA=='
                        }
                    }
                )
            ).data

            if (apiRest?.data?.access_token?.length) {
                commit('setApiToken', apiRest.data.access_token)

                cookies.set(TOKEN_SESSION_STORAGE, apiRest.data.access_token, '1d')
            }

            return Promise.resolve(apiRest.success)
        } else {
            return Promise.resolve(true)
        }
    },
    async start({ commit }) {
        const storedMotorcyclesData = getSessionStorage('__hsklep_motorcyclesData')

        if (storedMotorcyclesData) {
            commit('setMainMenu', {
                categories: {},
                motorcycles: JSON.parse(storedMotorcyclesData),
                manufacturers: {}
            })
        } else {
            const response = await axios.get(
                'index.php?route=apiopen/motorcycles/categories'
            )

            if (response?.data?.data) {
                setSessionStorage('__hsklep_motorcyclesData', JSON.stringify(response.data.data.categories))
            }

            commit('setMainMenu', {
                categories: {},
                motorcycles: response?.data?.data?.categories,
                manufacturers: {}
            })
        }
    }
}


export default createStore({
    state,
    mutations,
    actions,
    getters: {
        getAccessToken(state, getters) {
            if (state.access_token && state.access_token.value) {
                return state.access_token.value
            }

            let accessToken = cookies.get(TOKEN_SESSION_STORAGE)

            if (accessToken) {
                return accessToken
            }

            return null
        }
    },
    modules: {
        alertStore,
        cart,
        user,
    },
})