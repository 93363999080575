<template>
  <div class="text-center m-auto" :class="additionalContainerClass" aria-label="Loading...">
    <div class="sk-chase" :class="additionalClass">
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'additionalClass',
    'additionalContainerClass'
  ],
  name: 'WaitingLoader'
}
</script>
