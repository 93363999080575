const mutations = {
  showAlert(state, alert) {
    state.show = alert.content.length > 0 ? alert.show : false
    state.content = alert.content
    state.style = alert.style

    state.errors = alert.errors !== undefined ? alert.errors : {}
    if (state.show) {
      window.scrollTo(0, 0)
    }
  },
  destroyAlert(state) {
    state.show = false
    state.content = []
    state.style = 'success'
    state.errors = {}
  }
}

export default {
  state: {
    show: false,
    content: '',
    style: '',
    errors: {}
  },
  mutations,
}
