export default class Response {
  constructor(response = {}) {
    this.data = response.data || {}
    this.api = response.api || false
    this.success = response.success || ''
    this.errors = response.errors || ''
    this.error = response.error || []

    this.pagination = response.pagination ||
      this.data.pagination || {
        limit: null,
        page: null,
        total: null,
        url: '/'
      }

    return this
  }

  get(fields = 'data') {
    return this[fields] || null
  }
}
