export const loadIcons = async () => {
    const [
        {
            library
        },
        {
        faAngleUp,
        faAngleRight,
        faAngleLeft,
        faChevronUp,
        faChevronRight,
        faChevronDown,
        faFileDownload,
        faUser,
        faHeart,
        faShoppingCart,
        faHome,
        faCheck,
        faSearch,
        faSync,
        faPaperPlane,
        faTimes,
        faSearchPlus,
        faSearchMinus
    },{
        faFacebookF,
        faInstagram,
        faYoutube,
        faTwitter
    }] = await Promise.all([
        import('@fortawesome/fontawesome-svg-core'),
        import('@fortawesome/free-solid-svg-icons'),
        import('@fortawesome/free-brands-svg-icons')
    ])

    library.add(
        faAngleUp,
        faAngleRight,
        faAngleLeft,
        faChevronUp,
        faChevronRight,
        faChevronDown,
        faFileDownload,
        faFacebookF,
        faTwitter,
        faInstagram,
        faYoutube,
        faUser,
        faHeart,
        faShoppingCart,
        faHome,
        faCheck,
        faSearch,
        faSync,
        faPaperPlane,
        faTimes,
        faSearchPlus,
        faSearchMinus
    )
}
