

export const isStorageAvailable = () => {
    let cookiesAccepted = localStorage.getItem('__hsklep_cookies_modal_accepted')

    if (cookiesAccepted) {
      cookiesAccepted = JSON.parse(cookiesAccepted)

      return cookiesAccepted?.length && cookiesAccepted.includes('storage')
    }

    return false
}

export const isAnalitycsAvailable = () => {
    let cookiesAccepted = localStorage.getItem('__hsklep_cookies_modal_accepted')

    if (cookiesAccepted) {
      cookiesAccepted = JSON.parse(cookiesAccepted)

      return !!(cookiesAccepted?.length && cookiesAccepted.includes('analityc'))
    }

    return false
}

export const getSessionStorage = (key) => {
    if (!isStorageAvailable) {
        return null
    }

    return sessionStorage.getItem(key)
}

export const setSessionStorage = (key, val) => {
    if (!isStorageAvailable) {
        return null
    }

    return sessionStorage.setItem(key, val)
}

export const getLocalStorage = (key) => {
    if (!isStorageAvailable) {
        return null
    }

    return localStorage.getItem(key)
}

export const setLocalStorage = (key) => {
    if (!isStorageAvailable) {
        return null
    }

    return localStorage.setItem(key, val)
}