import axios from 'axios'
import store from '@/store'
import axiosRetry from 'axios-retry'

axiosRetry(axios, { retries: 3, retryDelay: () => { return 100 },  })

axios.interceptors.request.use(config => {
    config.timeout = 30000

    if (config.headers.apiauth) {
        const accessToken = store.getters.getAccessToken

        if (accessToken) {
            config.headers.Authorization = 'Bearer ' + accessToken
        } else {
            store.dispatch('token', { forceRefresh: true }).then(success => {
                    if (success) {
                        store.dispatch('getUser')
                    }
                }
            )

            config.headers.Authorization = 'Bearer ' + store.getters.getAccessToken
        }

        delete config.headers.apiauth
    }

    config.baseURL = 'https://api.husqvarnasklep.pl/'

    return config
})

axios.interceptors.response.use(
    response => response,
    error => {
        // Sentry.captureException(error)

        try {
            if (error.response && typeof error.response !== 'undefined' && error.response.status === 422) {
                store.commit('showAlert', {
                    show: true,
                    content: error.response.data.message,
                    style: 'danger',
                    errors: Object.values(error.response.data.errors).length
                        ? error.response.data.errors
                        : {}
                })
            } else {
                console.error(error)
            }
        } catch (e) {
            // Sentry.captureException(e)
        }

        console.error(error)

        return Promise.reject(error)
    }
)

export default axios