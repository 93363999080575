$('body').on('click', '.main-menu-box .dropdown > span', function(e) {
    // $('.bg-box').removeClass('show')
    $('.main-menu-box .dropdown').removeClass('show')
    $('.main-menu-box .dropdown > span').removeClass('show')
    $('.main-menu-box .dropdown .dropdown-menu').removeClass('show')
    const submenu = $(this)

    submenu.addClass('show')
    // $('.bg-box').addClass('show')
    submenu.parent().addClass('show')
    submenu.next('.dropdown-menu').addClass('show')
})

$(document).on('click', function(e) {
    if ($(e.target).closest('.dropdown.show').length === 0) {
        // $('.bg-box').removeClass('show')
        $('.main-menu-box .dropdown').removeClass('show')
        $('.main-menu-box .dropdown > span').removeClass('show')
        $('.main-menu-box .dropdown .dropdown-menu').removeClass('show')
    }
})