import axios from '@/plugins/axios'

export default function(context) {
    return {
        timeout: 10000,
        async search(text = '', page = 1, sort = 'availability') {
            const response = await axios.post(
                'index.php?route=rest/search/search&search=' + text + '&sort=' + sort,
                {
                    search: text,
                    page: page,
                    sort: sort
                },
                {
                    headers: {
                        apiauth: true
                    }
                }
            )

            return response.data
        },
        searchBy(text = '') {
            return axios.get(
                'index.php?route=apiopen/search/searchBy&part=' + text
            )
        },
        async setPart(part, optionModel = null) {
            const response = await axios.get(
                'index.php?route=husqvarna/prices_new/getPartInfo&n=' +
                part +
                '&option_number=' +
                optionModel,
                { timeout: this.timeout }
            )

            return response.data
        },
        async getPrice(id, part = null) {
            const response = await axios.get(
                'index.php?route=husqvarna/prices_new/getPartInfo2&n=' +
                id +
                (part ? '&p=' + part : ''),
                { timeout: this.timeout }
            )

            return response.data
        },
        addDynamicProduct(model) {
            return axios.get(
                'index.php?route=apiopen/cart/dynamicSearch&no_part=' + model,
                { progress: false }
            )
        },
        getCompatybility(part) {
            return axios.get(
                'index.php?route=apiopen/search_husqvarna/compatybility&part=' + part
            )
        }
    }
}
