import axios from '@/plugins/axios'
import { toast } from 'vue3-toastify'

const state = () => ({
  user: {},
  user_address: {},
  zones: {},
  countries: {},
  orders: {},
  ordersPagination: {},
  returns: {},
  returnsPagination: {},
  transactions: {},
  transactionBalans: {},
  transactionsPagination: {},
  logged: false,
  loading: false
})

const mutations = {
  setUser(state, values) {
    state.user = values
    state.logged = !!(values && values.customer_id !== null)
  },
  setLoading(state, value) {
    state.loading = value
  },
  setUserAddress(state, values) {
    state.user_address = values
  },
  setZones(state, values) {
    state.zones = values
  },
  setCountries(state, values) {
    state.countries = values
  },
  setOrderHistory(state, values) {
    state.orders = values.data
    state.ordersPagination = values.pagination
  },
  setReturns(state, values) {
    state.returns = values.data
    state.returnsPagination = values.pagination
  },
  setTransactions(state, values) {
    state.transactions = values.data.transactions
      ? values.data.transactions
      : []
    state.transactionBalans = values.data.user_balance
      ? values.data.user_balance
      : null
    state.transactionsPagination = values.pagination
  }
}

export const setErrors = (response) => {
  if (response?.data?.error?.length) {    
    for (const i in response.data.error) {
      if (response.data.error[i]) {
        toast.error(response.data.error[i])
      }
    }
  }
}

const actions = {
  async getUser({ commit }, forceRefresh = true) {
    if (!forceRefresh && state.user && state.user.logged) {
      return Promise.resolve(true)
    }

    commit('setLoading', true)

    let response

    try {
      response = await axios.get(
          'index.php?route=rest/account/account',
          {
            headers: {
              apiauth: true
            }
          }
      )
    } catch(e) {
      commit('setLoading', false)

      return Promise.resolve(null)
    }

    if (response.data.success) {
      commit('setUser', response.data.data)
    }

    setErrors(response)
    commit('setLoading', false)

    return Promise.resolve(response.data.data)
  },
  async getGuest({ commit }) {
    const response = await axios.get(
      'index.php?route=rest/guest/guest',
      {
        headers: {
          apiauth: true
        }
      }
    )

    return Promise.resolve(response.data.data)
  },
  async register({ commit }, post) {
    const response = await axios.post(
      'index.php?route=rest/register/register',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    if (response.data.success) {
      toast.success('Konto zostało utworzone.')
      commit('setUser', response.data.data)
    }

    setErrors(response)

    return Promise.resolve(response)
  },
  async login({ commit }, post) {
    const response = await axios.post(
      'index.php?route=rest/login/login',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    if (response.data.success) {
      toast.success('Zalogowano pomyślnie')
      commit('setUser', response.data.data)
    }

    setErrors(response)

    return Promise.resolve(response.data)
  },
  async newsletter({ commit }, post) {
    const response = await axios.get(
      'index.php?route=apiopen/guest/newsletter' + post
    )

    if (response?.data?.success?.length) {
      toast.success(response.data.success)
    }

    setErrors(response)

    return Promise.resolve(response)
  },
  async resetPassword({ commit }, post) {
    const response = await axios.post(
      'index.php?route=rest/reset/reset',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    if (response.data.success) {
      toast.success('Zmiany zostały zapisane')
      window.location.href = '/login'
    }

    setErrors(response)
  },
  async forgotten({ commit }, post) {
    const response = await axios.post(
      'index.php?route=rest/forgotten/forgotten',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    if (response.data.success) {
      toast.success(
        'Na podany adres email wysłano link do przypomnienia hasła'
      )
    }

    setErrors(response)
  },
  async accountEdit({ commit, dispatch }, post) {
    const response = await axios.put(
      'index.php?route=rest/account/account',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    if (response.data.success) {
      toast.success('Nowe dane zostały zapisane.')
      dispatch('getUser')
    }

    setErrors(response)
  },
  async accountChangePassword({ commit, dispatch }, post) {
    const response = await axios.put(
      'index.php?route=rest/account/password',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )
    if (response.data.success) {
      toast.success('Nowe dane zostały zapisane')
      dispatch('getUser')
    }

    setErrors(response)
  },
  async addOrEditAddress({ commit, dispatch }, post) {
    const url =
      post.address_id === 0
        ? 'index.php?route=rest/account/address'
        : 'index.php?route=rest/account/address&id=' + post.address_id
    const method = post.address_id === 0 ? 'post' : 'put'
    let response

    if (method === 'post') {
      response = await axios.post(url, post, {
        headers: {
          apiauth: true
        }
      })
    } else {
      response = await axios.put(url, post, {
        headers: {
          apiauth: true
        }
      })
    }

    if (response.data.success) {
      toast.success('Zmiany zostały zapisane')
    }

    setErrors(response)

    return Promise.resolve(response.data)
  },
  // potrzebne do moje-konto
  async getZones({ commit }, countryId = 170) {
    const response = await axios.get(
      'index.php?route=apiopen/settings/getZones&country_id=' + countryId
    )

    commit('setZones', response.data.zones)
  },
  async getCountries({ commit }) {
    const response = await axios.get(
      'index.php?route=apiopen/settings/getCountries'
    )

    commit('setCountries', response.data.countries)
  },
  async getUserAddress({ commit }, addressId = 0) {
    let query = ''

    if (addressId) {
      query = '&id=' + addressId
    }

    const response = await axios.get(
      'index.php?route=rest/account/address' + query,
      {
        headers: {
          apiauth: true
        }
      }
    )

    if (response.data.success && addressId === 0) {
      commit('setUserAddress', response.data.data.addresses || [])
    }

    setErrors(response)

    return Promise.resolve(response.data.data)
  },
  async getOrdersHistory({ commit }, page = 1) {
    let query = ''

    if (Number.isInteger(page)) {
      query = '&page=' + page
    }

    if (page.order_id) {
      query += '&id=' + page.order_id
    }

    const response = await axios.get(
      'index.php?route=rest/order/orders' + query,
      {
        headers: {
          apiauth: true
        }
      }
    )

    commit('setOrderHistory', { data: response.data.data, pagination: response.data.pagination })
  },
  async addReturn({ commit }, post) {
    return await axios.post(
        'index.php?route=rest/return/returns',
        post,
        {
          headers: {
            apiauth: true
          }
        }
    )
  },
  async getReturns({ commit }, page = 1) {
    let query = ''
    if (Number.isInteger(page)) {
      query = '&page=' + page
    }
    if (page.return_id) {
      query += '&id=' + page.return_id
    }

    const response = await axios.get(
      'index.php?route=rest/return/returns' + query,
      {
        headers: {
          apiauth: true
        }
      }
    )
    const res = { data: response.data.data, pagination: response.data.pagination }
    commit('setReturns', res)
    return res
  },
  async getTransactions({ commit }, page = 1) {
    let query = ''
    if (Number.isInteger(page)) {
      query = '&page=' + page
    }

    const response = await axios.get(
      'index.php?route=rest/account/transactions' + query,
      {
        headers: {
          apiauth: true
        }
      }
    )
    const res = { data: response.data.data, pagination: response.data.pagination }
    commit('setTransactions', res)
    return res
  }
}

export default {
  state,
  mutations,
  actions
}