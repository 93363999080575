import {
    createRouter,
    createWebHistory
} from 'vue-router'
import {
    TITLE
} from '@/plugins/constants'
import tokenMiddleware from '@/middleware/token.js'
import cartMiddleware from '@/middleware/cart.js'
import authMiddleware from '@/middleware/auth.js'
import store from '@/store'

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: () => import('@/views/HomeView'),
        meta: {
            title: 'Oryginalne części Husqvarna, WP, KTM, GASGAS',
            canonical: 'https://husqvarnasklep.pl/'
        }
    },
    {
        path: '/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/rok-modelowy/:year?/:series?/:model?',
        name: 'DiagramsSearchContainerViewYear',
        component: () => import('@/views/DiagramsSearchContainerView'),
        meta: {
            title: 'Wyszukiwarka części oryginalnych do motocykli Husqvarna',
        },
        props: route => ({
            year: route.params.year,
            series: route.params.series,
            model: route.params.model
        }),
    },
    {
        path: '/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/model/:series?/:model?',
        name: 'DiagramsSearchContainerViewModel',
        component: () => import('@/views/DiagramsSearchContainerView'),
        meta: {
            title: 'Wyszukaj części po modelu motocykla'
        },
        props: route => ({
            series: route.params.series,
            model: route.params.model
        }),
    },
    {
        path: '/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/number/:number?',
        name: 'DiagramsSearchContainerViewNumber',
        component: () => import('@/views/DiagramsSearchContainerView'),
        meta: {
            title: 'Wyszukaj część po numerze seryjnym'
        },
        props: route => ({
            number: route.params.number,
        }),
    },
    {
        path: '/czesci-oryginalne-oem-husqvarna-ktm-gasgas-wp/vin/:vin?',
        name: 'DiagramsSearchContainerViewVin',
        component: () => import('@/views/DiagramsSearchContainerView'),
        meta: {
            title: 'Wyszukaj części po numerze VIN motocykla'
        },
        props: route => ({
            vin: route.params.vin,
        }),
    },
    {
        path: '/czesci-oryginalne-oem-husqvarna-wp-ktm-gasgas/:modelDuplicated/:diagram/:diagramName/rok-modelowy/:year/:series/:model',
        name: 'DiagramViewByYear',
        component: () => import('@/views/DiagramView'),
        meta: {
            title: 'Schemat części'
        },
        props: route => ({
            model: route.params.model,
            diagram: route.params.diagram
        }),
    },
    {
        path: '/czesci-oryginalne-oem-husqvarna-wp-ktm-gasgas/:modelDuplicated/:diagram/:diagramName/model/:series/:model',
        name: 'DiagramViewByModel',
        component: () => import('@/views/DiagramView'),
        meta: {
            title: 'Schemat części'
        },
        props: route => ({
            model: route.params.model,
            diagram: route.params.diagram
        }),
    },
    {
        path: '/motocykle-nowe-husqvarna-salon-poznan',
        name: 'MotorcyclesCategoriesListView',
        component: () => import('@/views/MotorcyclesCategoriesListView'),
        meta: {
            title: 'Katalog motocykli Husqvarna'
        },
    },
    {
        path: '/motocykle-nowe-husqvarna-salon-poznan/:categorySlug',
        name: 'MotorcycleCategoryView',
        component: () => import('@/views/MotorcycleCategoryView'),
        meta: {
            title: 'Kategoria motocykli'
        },
    },
    {
        path: '/motocykle-nowe-husqvarna-salon-poznan/:categorySlug/:motorcycleSlug',
        name: 'MotorcycleView',
        component: () => import('@/views/MotorcycleView'),
        meta: {
            title: 'Motocykl'
        },
    },
    {
        path: '/pl/:slug',
        name: 'StaticPageView',
        component: () => import('@/views/StaticPageView'),
        meta: {
            title: 'Strona statyczna'
        },
    },
    {
        path: '/kontakt',
        name: 'ContactView',
        component: () => import('@/views/ContactView'),
        meta: {
            title: 'Kontakt'
        },
    },
    {
        path: '/results',
        name: 'ResultsView',
        component: () => import('@/views/ResultsView'),
        meta: {
            title: 'Wyniki wyszukiwania'
        }
    },
    {
        path: '/akcesoria',
        name: 'AccessoriesView',
        component: () => import('@/views/AccessoriesView'),
        meta: {
            title: 'Oryginalne akcesoria do motocykli'
        }
    },
    {
        path: '/login',
        name: 'LoginView',
        component: () => import('@/views/LoginView'),
        meta: {
            title: 'Logowanie'
        }
    },
    {
        path: '/moje-konto',
        name: 'AccountView',
        component: () => import('@/views/AccountView'),
        meta: {
            title: 'Moje konto'
        },
        children: [
            {
                path: 'edycja-danych',
                name: 'AccountEditView',
                component: () => import('@/views/AccountViews/AccountEditView.vue')
            },
            {
                path: 'historia-zamowien',
                name: 'AccountOrderHistoryView',
                component: () => import('@/views/AccountViews/AccountOrderHistoryView.vue')
            },
            {
                path: 'ksiazka-adresowa',
                name: 'AccountAddressesView',
                component: () => import('@/views/AccountViews/AccountAddressesView.vue')
            },
            {
                path: 'newsletter',
                name: 'AccountNewsletterView',
                component: () => import('@/views/AccountViews/AccountNewsletterView.vue')
            },
            {
                path: 'reklamacje',
                name: 'AccountComplaintsView',
                component: () => import('@/views/AccountViews/AccountComplaintsView.vue')
            },
            {
                path: 'transakcje',
                name: 'AccountPaymentsView',
                component: () => import('@/views/AccountViews/AccountPaymentsView.vue')
            },
            {
                path: 'zmiana-hasla',
                name: 'AccountChangePasswordView',
                component: () => import('@/views/AccountViews/AccountChangePasswordView.vue')
            },
        ]
    },
    {
        path: '/koszyk',
        name: 'CartView',
        component: () => import('@/views/CartView'),
        meta: {
            title: 'Koszyk'
        },
    },
    {
        path: '/koszyk/dane-adresowe',
        name: 'CartAddressesView',
        component: () => import('@/views/CartViews/CartAddressesView'),
        meta: {
            title: 'Koszyk - dane adresowe'
        },
    },
    {
        path: '/koszyk/platnosc-i-wysylka',
        name: 'CartPaymentAndShipment',
        component: () => import('@/views/CartViews/CartPaymentAndShipmentView'),
        meta: {
            title: 'Koszyk - dane adresowe'
        },
    },
    {
        path: '/koszyk/potwierdz',
        name: 'CartConfirmView',
        component: () => import('@/views/CartViews/CartConfirmView'),
        meta: {
            title: 'Koszyk - potwierdź zamówienie'
        },
    },
    {
        path: '/koszyk/dziekujemy',
        name: 'CartFinishView',
        component: () => import('@/views/CartViews/CartFinishView'),
        meta: {
            title: 'Dziękujemy za złożenie zamówienia'
        },
    },
    {
        path: '/koszyk/firstdata-success',
        name: 'CartOnlinePaymentSuccessView',
        component: () => import('@/views/CartViews/CartOnlinePaymentSuccessView'),
        meta: {
            title: 'Płatność została przyjęta'
        },
    },
    {
        path: '/koszyk/firstdata-error',
        name: 'CartOnlinePaymentErrorView',
        component: () => import('@/views/CartViews/CartOnlinePaymentErrorView'),
        meta: {
            title: 'Płatność została przyjęta'
        },
    },
    {
        path: '/reset-hasla',
        name: 'ResetPasswordView',
        component: () => import('@/views/ResetPasswordView'),
        meta: {
            title: 'Reset hasła'
        },
    },
    {
        path: '/:category/:productNumber/:productName',
        name: 'ProductView',
        component: () => import('@/views/ProductView'),
        meta: {
            title: 'Produkt'
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundView',
        component: () => import('@/views/Error404View'),
        meta: {
            title: 'Błąd 404 - nie znaleziono strony'
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.meta) {
        if (to.meta.title) {
            document.title = `${to.meta.title} - ${TITLE}`
        } else {
            document.title = TITLE
        }

        if (to.meta.canonical) {
            const canonicalLink = document.createElement('link')
            canonicalLink.setAttribute('rel', 'canonical')
            canonicalLink.setAttribute('href', to.meta.canonical)
            document.head.appendChild(canonicalLink)
        }
    } else {
        document.title = TITLE
    }

    await tokenMiddleware({ store })
    await authMiddleware({ store, to })
    await cartMiddleware({ store })
    scrollTo(0, 0)

    next()
})

export default router