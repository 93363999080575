import axios from '@/plugins/axios'
import { toast } from 'vue3-toastify'

const state = () => ({
  product_popup: false,
  products: '',
  total: '',
  payment_method: '',
  shipping_method: '',
  cart: {},
  deliverys: {},
  payments: {},
  payment_address: {
    email: '',
    firstname: '',
    lastname: '',
    telephone: '',
    address_1: '',
    city: '',
    postcode: '',
    country_id: 170,
    zone_id: ''
  },
  shipping_address: {
    email: '',
    firstname: '',
    lastname: '',
    telephone: '',
    address_1: '',
    city: '',
    postcode: '',
    country_id: 170,
    zone_id: ''
  },
  searchResult: [],
  modal: {
    show: false,
    msg: '',
    option: false
  },
  blocked: false, // jest ok
  loadingCart: false
})

const mutations = {
  setModal(state, value) {
    state.modal = value
  },
  setCart(state, value) {
    state.cart = value
  },
  loadingCart(state, value) {
    state.loadingCart = value
  },
  setBlocked(state, value) {
    state.blocked = value
  },
  setDeliverys(state, value) {
    /*
    const free_delivery_code = 'free'
    let value_formatted = {}

    if (free_delivery_code in value && state && state.cart && state.cart.total_raw < 399.5) {
      for (const shipping_code in value) {
        if (shipping_code !== free_delivery_code) {
          value_formatted[shipping_code] = value[shipping_code]
        }
      }
    } else {
      value_formatted = value
    }*/

    state.deliverys = value
  },
  setPayments(state, value) {
    state.payments = value
  },
  setPaymentAddress(state, value) {
    state.payment_address = value
  },
  setShippingAddress(state, value) {
    state.shipping_address = value
  },
  setShowPopup(state, value) {
    state.product_popup = value
  },
  setSearchResult(state, value) {
    state.searchResult = value
  }
}

const actions = {
  async remove({ dispatch }, key) {
    const res = await axios.delete('/api/rest/cart/' + key, {
      headers: {
        apiauth: true
      }
    })
    if (res.error.length) {
      for (const i in res.error) {
        toast.error(res.error[i])
      }
    }
    if (res.success === 1) {
      toast.success('Produkt został usunięty')
    }
    dispatch('refreshCart').then(e => {
      return Promise.resolve(e)
    })
  },
  async removeById({ dispatch }, key) {
    const res = await axios.delete(
      'index.php?route=rest/cart/cart&product_id=' + key,
      {
        headers: {
          apiauth: true
        }
      }
    )
    if (res.error.length) {
      for (const i in res.error) {
        toast.error(res.error[i])
      }
    }
    if (res.success === 1) {
      toast.success('Produkt został usunięty')
    }
    dispatch('refreshCart')
    return Promise.resolve(res)
  },
  async add({ commit, dispatch }, data) {
    const response = await axios.post(
      'index.php?route=rest/cart/cart',
      {
        product_id: data.product_id,
        quantity: typeof data.quantity !== 'undefined' ? data.quantity : 1,
        option: data.option
      },
      {
        headers: {
          apiauth: true
        }
      }
    )

    const responseData = response.data

    try {
      if (responseData.error[0]) {
        toast.info('Produkt wymaga wybrania dodatkowych opcji')
        commit('setShowPopup', data.product_id)
      }
    } catch (e) {
       toast.info('Wystąpił nieoczekiwany błąd')
       commit('setShowPopup', data.product_id)
    }

    if (responseData.success === 1) {
      toast.success('Produkt został dodany do koszyka', {
        duration: 4500
      })
    }

    dispatch('refreshCart').then(e => {
      return Promise.resolve(responseData)
    })
  },
  async addById({ commit, dispatch }, data) {
    const query =
      '&product_id=' +
      data.product_id +
      '&quantity=' +
      (typeof data.quantity !== 'undefined' ? data.quantity : 1)
    // TODO zmienic na restapi

    const response = await axios.get(
      'index.php?route=apiopen/cart/addById' + query
    )

    dispatch('add', { product_id: response.data.data.product_id, quantity: 1 })
  },
  async addByModel({ commit, dispatch }, noPart) {
    const query = '&product_id=' + noPart
    const product = await axios.get(
      'index.php?route=apiopen/cart/addByModel' + query
    )

    dispatch('add', { product_id: product.data.product_id, quantity: 1 })
  },
  async checkAvailability({ commit, dispatch }, data) {
    try {
      const res = await axios.put(
        'index.php?route=rest/products/products',
        {
          product_number: data.productNumber,
        },
        {
          headers: {
            apiauth: true
          }
        }
      )

      return res.data
    } catch (e) {
      console.error(e)

      if (e.status_code !== 404) {
        console.error(e)
      }
    }
  },
  async updateQuantity({ commit, dispatch }, post) {
    const response = await axios.put(
      'index.php?route=rest/cart/cart',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    dispatch('refreshCart', post.loading === null || typeof post.loading === 'undefined' ? true : post.loading)

    return response
  },
  async refreshCart({ commit }, load = true) {

    if (load) {
      await commit('loadingCart', true)
    }

    const response = await axios.get('/api/rest/cart', {
      headers: {
        apiauth: true
      },
      progress: false
    })

    const error = response.data.error,
        data = response.data.data

    if (error && error.length > 0) {
      commit('setBlocked', true)

      console.error(error)
    }

    await commit('setCart', data)

    if (load) {
      await commit('loadingCart', false)
    }
  },
  async getDeliverys({ commit }, payment) {
    const delivery = await axios.get(
      'index.php?route=apiopen/cart/getshippingmethods&payment_method=' +
        payment
    )

    commit('setDeliverys', delivery.data.data)
  },
  async getPayments({ commit }) {
    const payments = await axios.get(
      'index.php?route=apiopen/cart/getpaymentmethods'
    )

    commit('setPayments', payments.data.data)
  },
  // KASA
  async savePaymentAddress({ commit }, post) {
    const response = await axios.post(
      'index.php?route=rest/payment_address/paymentaddress',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    return response.data
  },
  async saveShippingAddress({ commit }, post) {
    const response = await axios.post(
      'index.php?route=rest/shipping_address/shippingaddress',
      post,
      {
        headers: {
          apiauth: true
        }
      }
    )

    return response.data
  },
  async getConfirm() {
    const response = await axios.get(
      'index.php?route=rest/confirm/confirm',
      {
        headers: {
          apiauth: true
        }
      }
    )

    return Promise.resolve(response.data)
  },
  async saveOrder() {
    const response = await axios.post(
      'index.php?route=rest/confirm/confirm',
      {},
      {
        headers: {
          apiauth: true
        }
      }
    )

    return Promise.resolve(response.data)
  },
  async saveOrderToDataBase() {
    const response = await axios.put(
      'index.php?route=rest/confirm/confirm',
      {},
      {
        headers: {
          apiauth: true
        }
      }
    )

    return Promise.resolve(response.data)
  },
  async saveParcelAddress({ commit }, data) {
    const postData = {
      parcelId: data.parcelId,
      parcelAddress: data.parcelAddress,
      orderId: data.orderId,
      shippingCode: data.shippingCode
    }

    const response = await axios.put(
      'index.php?route=rest/confirm/confirm',
      postData,
      {
        headers: {
          apiauth: true
        }
      }
    )

    return Promise.resolve(response.data)
  },
  async firstDataReject({ commit }, order_id) {
    console.log(order_id)

    const data = {
      order_id: order_id,
      update_type: 'reject'
    }

    await axios.put(
      'index.php?route=rest/firstdatapolcard/payments',
      data,
      {
        headers: {
          apiauth: true
        }
      }
    )
  },
  async firstDataConfirm({ commit }, order_id) {
    const data = {
      order_id: order_id,
      update_type: 'confirm'
    }

    await axios.put(
      'index.php?route=rest/firstdatapolcard/payments',
      data,
      {
        headers: {
          apiauth: true
        }
      }
    )
  },
}

export default {
  state,
  mutations,
  actions
}
