<template>
  <footer class="bg-footer">
    <div class="container-fluid content-box position-relative">
      <div @click="scrollTop()" class="go-top-box text-right cursor-pointer">
        <font-awesome-icon icon="angle-up" class="text-warning" />
      </div>
      <div class="row menu-footer-box">
        <div class="col-lg-6">
          <div class="row h-100">
            <div class="col-md-6">
              <div class="border-right-gray">
                <span class="text-white font-size-26 font-weight-bold mb-4">SKLEP</span>
                <ul class="list-unstyled">
                  <li class="mb-2">
                    <a class="text-footer font-size-18"
                       href="/moje-konto"
                    >MOJE KONTO</a
                    >
                  </li>
                  <li class="mb-2">
                    <a
                        class="text-footer font-size-18"
                        href="/moje-konto/historia-zamowien"
                    >MOJE ZAMÓWIENIA
                    </a>
                  </li>
                  <li class="mb-2">
                    <a class="text-footer font-size-18" href="/moje-konto/reklamacje">
                      REKLAMACJE I ZWROTY</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="border-right-gray">
                <span class="text-white font-size-20 font-weight-bold mb-4">INFORMACJE</span>
                <ul class="list-unstyled">
                  <li
                      v-for="(page, index) in pages"
                      :key="index"
                      class="mb-2"
                  >
                    <a
                        v-if="!targetBlank(page.slug)"
                        :href="page.href"
                        class="text-footer font-size-18"
                    >{{ page.title }}</a
                    >
                    <a
                        v-else
                        :href="page.slug"
                        class="text-footer font-size-18"
                        target="_blank"
                    >{{ page.title }}</a
                    >
                  </li>
                  <li class="mb-2">
                    <a 
                        href="#"
                        class="text-footer font-size-18"
                        @click="showCookiesModal" 
                    > 
                      Ustawienia cookies
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="border-right-gray border-0">
            <span class="text-white font-size-26 font-weight-bold mb-4">NEWSLETTER</span>
            <div class="newsletter-box mb-4">
              <form @submit.prevent="submitNewsletter()">
                <div class="form-group">
                  <label for="email" class="text-white mb-4"
                  >Jeśli chcesz być na bieżąco z nowościach na stronie wpisz
                    poniżej swój adres e-mail</label
                  >
                  <div class="position-relative">
                    <input
                        id="email"
                        v-model="email"
                        type="text"
                        class="form-control"
                        name="email"
                        placeholder="Adres email"
                        required
                        autocomplete="on"
                    />
                    <span v-if="errors.email" class="text-danger">
                        {{ errors.email }}
                      </span>
                    <button
                        type="submit"
                        class="btn btn-warning w-100 border-radius-0 py-2 d-block"
                    >
                      dodaj email
                    </button>
                  </div>
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                      id="customCheck"
                      v-model="agree"
                      type="checkbox"
                      class="custom-control-input"
                      name="example1"
                  />
                  <label
                      class="custom-control-label text-white pl-2 cursor-pointer"
                      for="customCheck"
                  >
                    Akceptuję
                    <a
                        @click.prevent="$store.commit('setTermModal', true)"
                        href="#"
                        class="font-size-18"
                    >regulamin newslettera </a
                    >i RODO.</label
                  >
                </div>
              </form>
              <hr />
            </div>

            <div class="row">
              <div class="col-md-6">
                <span class="text-white font-size-26 font-weight-bold mb-4">NOWOŚCI</span>
                <div class="social-box d-flex mb-md-3 mb-4">
                  <div class="item-box mr-4 position-relative">
                    <a target="_blank" href="https://www.facebook.com/HusqvarnaPoznan" aria-label="Facebook">
                      <font-awesome-icon :icon="['fab', 'facebook-f']" />
                    </a>
                  </div>
                  <div class="item-box mr-4 position-relative">
                    <a target="_blank" href="https://www.instagram.com/dirtbike.pl_husqvarna_poznan" aria-label="Instagram">
                      <font-awesome-icon :icon="['fab', 'instagram']" />
                    </a>
                  </div>
                  <div class="item-box mr-4 position-relative">
                    <a href="#" aria-label="HusqvarnaSKLEP" style="background:gray">
                      <font-awesome-icon :icon="['fab', 'youtube']" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <span class="text-white font-size-24 font-weight-bold mb-4">KONTAKT</span>
                <div class=" text-uppercase mb-2 link-footer">
                  TEL.
                  <a href="tel:+48 729 972 998" class="link-footer font-size-18">729 972 998</a>
                </div>

                <a
                    href="mailto:zamowienia@dirtbike.pl"
                    class="text-uppercase link-footer font-size-18"
                >zamowienia@dirtbike.pl</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="copy-box mt-5 pb-5">
        <div class="row text-white align-items-center">
          <div class="col-md-6">
            © Copyright by husqvarnaSKLEP.pl <br />
            <span class="pl-3">All rights reserved.</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from '@/plugins/axios'
import { getSessionStorage, setSessionStorage } from '@/plugins/storage'
import { toast } from 'vue3-toastify'

export default {
  data() {
    return {
      pages: [],
      errors: {
        email: ''
      },
      agree: false,
      email: '',
      ver: '3.11.1',
    }
  },
  watch: {
    agree(e) {
      this.checkboxShow = !e
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    showCookiesModal() {
        this.$store.commit('setCookiesModalShow', true)
    },
    async init() {
      const storedPagesData = getSessionStorage('__hsklep_footerPagesData')

      if (storedPagesData && typeof storedPagesData !== 'undefined' && storedPagesData !== 'undefined') {
        this.pages = JSON.parse(storedPagesData)
      } else {
        const response = await axios.get(
          'index.php?route=apiopen/settings/information&position=bottom',
          { progress: false }
        )

        const pages = response.data.pages

        setSessionStorage('__hsklep_footerPagesData', JSON.stringify(pages))

        this.pages = pages
      }
    },
    scrollTop() {
      $('html, body').animate({ scrollTop: 0 }, 1000)
    },
    async submitNewsletter() {
      this.errors.email = ''

      if (!(this.email?.length && this.email?.length > 4 && this.email.includes('@') && this.email.includes('.'))) {
        this.errors.email = 'Wprowadzono niepoprawny e-mail.'
        toast.error('Wprowadzono niepoprawny adres e-mail.')

        return
      }

      if (this.agree) {
        await this.$store.dispatch('newsletter', '&email=' + this.email)
        toast.success('Użytkownik został dodany do newslettera.')

        this.email = ''
      } else {
        toast.warning('Zgody nie zostały zaakceptowane. Nie udało się dodać użytkownika do newslettera.')
      }

      return false
    }
  }
}
</script>
